import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { LEGAL_PERSON } from '../../../../../../../utility/SourceAPI'

export const getLegalPersons = createAsyncThunk('appLegalPersons/getLegalPersons', async () => {
    const response = await axios.get(LEGAL_PERSON)
    return {
        data: response.data
    }
})

export const addLegalPerson = createAsyncThunk('appLegalPersons/addLegalPerson', async (legalPerson, { dispatch }) => {
    const response = await axios.post(LEGAL_PERSON, legalPerson)
    await dispatch(getLegalPersons())
    return {
        data: response.data
    }
})

export const legalPersonsSlice = createSlice({
    name: 'appLegalPersons',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getLegalPersons.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default legalPersonsSlice.reducer