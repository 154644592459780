import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import axios from "axios"
import { PAYMENT_SERVICES } from '../../../../../utility/SourceAPI'

const config = {
    headers: {
        'Content-Type': 'multipart/form-data;boundary=boundary'
    }
}

export const getPaymentServices = createAsyncThunk('appServices/getPaymentServices', async () => {
    const response = await axios.get(PAYMENT_SERVICES)
    return {
        data: response.data
    }
})

export const getPaymentServicesByStatus = createAsyncThunk('appServices/getPaymentServicesByStatus', async () => {
    const response = await axios.get(`${PAYMENT_SERVICES}?status=pending&is_transfer=true`)
    return {
        data: response.data
    }
})

export const getReleasePayments = createAsyncThunk('appServices/getReleasePayments', async () => {
    const response = await axios.get(`${PAYMENT_SERVICES}?is_deleteable=true`)
    return {
        data: response.data
    }
})

export const releasePaymentService = createAsyncThunk('appServices/releasePaymentService', async (service, { dispatch }) => {
    const response = await axios.put(`${PAYMENT_SERVICES}authorized_deleted/${service}`, service)
    await dispatch(getReleasePayments())
    return {
        data: response.data
    }
})

export const deletePaymentService = createAsyncThunk('appServices/deletePaymentService', async (service, { dispatch }) => {
    const response = await axios.delete(`${PAYMENT_SERVICES}${service}`)
    await dispatch(getPaymentServices())
    return {
        data: response.data
    }
})


export const uploadVoucherService = createAsyncThunk('appServices/uploadVoucherService', async (service, { dispatch }) => {
    const id = service.get('id')
    const response = await axios.put(`${PAYMENT_SERVICES}update_voucher/${id}`, service, config)
    await dispatch(getPaymentServices())
    return {
        data: response.data
    }
})

export const replaceVoucherService = createAsyncThunk('appServices/replaceVoucherService', async (service, { dispatch }) => {
    const id = service.get('id')
    const response = await axios.put(`${PAYMENT_SERVICES}update_voucher/${id}`, service, config)
    await dispatch(getPaymentServices())
    return {
        data: response.data
    }
})

export const paymentServicesSlice = createSlice({
    name: 'appPaymentServices',
    initialState: {
        data: [],
        releasePayments: [],
        paymentsByStatus: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getPaymentServices.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        builder.addCase(getReleasePayments.fulfilled, (state, action) => {
            state.releasePayments = action.payload.data
        })
        builder.addCase(getPaymentServicesByStatus.fulfilled, (state, action) => {
            state.paymentsByStatus = action.payload.data
        })
    }
})

export default paymentServicesSlice.reducer