// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { COMPANIES_ENDPOINT, UPDATE_COMPANIES, UPDATE_COMPANIES_LABEL } from "../../../../../utility/SourceAPI"

export const getCompanies = createAsyncThunk('appCompanies/getCompanies', async (_, { getState, dispatch }) => {
    const state = getState()
    const cachedData = state?.companies.data

    if (state?.companies.data?.length > 0 && !state?.companies.isRequested) {
        return {
            data: cachedData
        }
    }

    const response = await axios.get(COMPANIES_ENDPOINT)

    // eslint-disable-next-line no-use-before-define
    dispatch(disableRequest())

    return {
        data: response.data
    }
})

export const updateCompany = createAsyncThunk('appCompanies/updateCompany', async (data, { dispatch }) => {
    const response = await axios.put(UPDATE_COMPANIES.concat(data.rfc), data)
    await dispatch(getCompanies())
    return response.data
})

export const companiesLabels = createAsyncThunk('appCompanies/companiesLabels', async (data, { dispatch }) => {
    const response = await axios.put(UPDATE_COMPANIES_LABEL.concat(`${data.rfc}`), data)
    await dispatch(getCompanies())
    return response.data
})

export const updateStatus = createAsyncThunk('appCompanies/updateStatus', async (data, { dispatch }) => {
    const response = await axios.put(COMPANIES_ENDPOINT.concat(`status/${data.rfc}`), data)
    await dispatch(getCompanies())
    return response.data
})

export const companyDates = createAsyncThunk('appCompanies/companyDates', async (data, { dispatch }) => {
    const response = await axios.put(COMPANIES_ENDPOINT.concat(`dates/${data.rfc}`), data)
    await dispatch(getCompanies())
    return response.data
})

export const appCompaniesSlice = createSlice({
    name: 'appCompanies',
    initialState: {
        data: [],
        isRequested: true
    },
    reducers: {
        resetCompaniesData: (state) => {
            state.data = []
        },
        enableRequest: (state) => {
            state.isRequested = true
        },
        disableRequest: (state) => {
            state.isRequested = false
        }
    },
    extraReducers: builder => {
        builder.addCase(getCompanies.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export const { resetCompaniesData, enableRequest, disableRequest } = appCompaniesSlice.actions

export const addCompany = createAsyncThunk('appCompanies/addCompany', async (company, { dispatch }) => {
    dispatch(resetCompaniesData())
    const response = await axios.post(COMPANIES_ENDPOINT, company)
    await dispatch(getCompanies())
    return response.data
})

export default appCompaniesSlice.reducer