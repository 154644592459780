// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { CHECKS, CHECKS_REMAINING, UPLOAD_LAYOUT_ENDPOINT } from '../../../../../../utility/SourceAPI'
import {
    formattedDatePicker, formattedDateTimePicker, minutes,
    seconds,
    miliseconds
} from '../../../../../../utility/formats/formattedDate'

const config = {
    headers: {
        'Content-Type': 'multipart/form-data;boundary=boundary'
    }
}

export const getChecks = createAsyncThunk('appChecks/getChecks', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? CHECKS.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes + 5}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}`) : CHECKS)
    return {
        data: response.data
    }
})

export const getTransitChecks = createAsyncThunk('appChecks/getTransitChecks', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? `${data.account !== undefined ? `${CHECKS}?bank_account_id=${data.account}&transit=true&end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes + 5}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}` : `${CHECKS}?transit=true&end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes + 5}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}`} ` : data.account !== undefined ? `${CHECKS}?transit=true&bank_account_id=${data.account}` : `${CHECKS}?transit=true`)
    return {
        data: response.data
    }
})

export const getDeletedChecks = createAsyncThunk('appChecks/getDeletedChecks', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? `${CHECKS}?deleted=true&end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes + 5}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}` : `${CHECKS}?deleted=true`)
    return {
        data: response.data
    }
})

export const getChecksRemaining = createAsyncThunk('appChecks/getChecksRemaining', async (accoount) => {
    const response = await axios.get(CHECKS_REMAINING.concat(accoount))
    return {
        data: response.data
    }
})

export const addCheck = createAsyncThunk('appChecks/addCheck', async (check, { dispatch }) => {
    const response = await axios.post(CHECKS, check)
    await dispatch(getChecks())
    return response.data
})

export const updateCheck = createAsyncThunk('appChecks/updateCheck', async (check, { dispatch }) => {
    const response = await axios.put(`${CHECKS}${check.id}`, check)
    await dispatch(getChecks())
    return response.data
})

export const updateTransitCheck = createAsyncThunk('appChecks/updateTransitCheck', async (check, { dispatch }) => {
    const id = parseInt(check.get("id"))
    const response = await axios.put(`${CHECKS}update-transit/${id}`, check)
    await dispatch(getChecks())
    return response.data
})

export const updateVoucherCheck = createAsyncThunk('appCash/updateVoucherCheck', async (voucher, { dispatch }) => {
    const id = voucher.get('id')
    const response = await axios.put(CHECKS.concat(`update_voucher/${id}`), voucher, config)
    await dispatch(getChecks())
    return response.data
})
export const getLayoutChecks = createAsyncThunk('appChecks/getLayoutChecks', async () => {
    const response = await axios.get(UPLOAD_LAYOUT_ENDPOINT.concat('checks/'), { responseType: 'blob' })
    return response.data
})

export const getLayoutChecksBlank = createAsyncThunk('appChecks/getLayoutChecksBlank', async () => {
    const response = await axios.get(UPLOAD_LAYOUT_ENDPOINT.concat('blanck_checks/'), { responseType: 'blob' })
    return response.data
})

export const uploadLayoutChecks = createAsyncThunk('appChecks/uploadLayout', async (layout) => {
    const response = await axios.post(UPLOAD_LAYOUT_ENDPOINT.concat('checks/'), layout, config)
    return response.data

})
export const uploadLayoutChecksBlank = createAsyncThunk('appChecks/uploadLayoutBlank', async (layout) => {
    const response = await axios.post(UPLOAD_LAYOUT_ENDPOINT.concat('blank_checks/'), layout, config)
    return response.data

})

export const updateStatusCheck = createAsyncThunk('appChecks/updateStatusCheck', async (check, { dispatch }) => {
    const response = await axios.put(`${CHECKS}update-status/${check.check_id}?status_check=${check?.status}`, check)
    const data = { }
    await dispatch(getChecks(data))
    return response.data
})

export const deleteCheck = createAsyncThunk('appChecks/deleteCheck', async (check, { dispatch }) => {
    const response = await axios.delete(`${CHECKS}${check}`)
    await dispatch(getChecks())
    return response.data
})

export const appChecksSlice = createSlice({
    name: 'appChecks',
    initialState: {
        checks: [],
        checksRemaining: [],
        checksDeleted: [],
        checksTransit: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getChecks.fulfilled, (state, action) => {
            state.checks = action.payload.data
        })
        builder.addCase(getChecksRemaining.fulfilled, (state, action) => {
            state.checksRemaining = action.payload.data
        })
        builder.addCase(getDeletedChecks.fulfilled, (state, action) => {
            state.checksDeleted = action.payload.data
        })
        builder.addCase(getTransitChecks.fulfilled, (state, action) => {
            state.checksTransit = action.payload.data
        })
    }
})
export default appChecksSlice.reducer